define("discourse/plugins/discourse-tickets/discourse/components/tickets-controls", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-tickets/discourse/lib/ticket-utilities", "discourse/lib/show-modal", "discourse/lib/ajax", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _decorators, _ticketUtilities, _showModal, _ajax, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const ticketTypes = ['priority', 'status', 'reason'];
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)('topic.is_ticket'), _dec2 = (0, _decorators.observes)('priority', 'status', 'reason'), _dec3 = (0, _decorators.observes)('topic.is_ticket'), (_obj = {
    classNames: 'tickets-controls',
    notTicket: (0, _computed.not)('topic.is_ticket'),
    includeUsernames: null,
    hasGroups: null,
    taskActions: (0, _service.inject)(),
    didInsertElement() {
      this.setup();
    },
    setup() {
      const topic = this.get('topic');
      const ticketTags = this.get('site.ticket_tags');
      const currentTags = topic.get('tags');
      ticketTypes.forEach(type => {
        let list = ticketTags[type];
        let value = '';
        if (list && currentTags) {
          value = currentTags.find(t => list.indexOf(t) > -1);
        }
        let props = {};
        props[type] = value;
        props[`${type}List`] = (0, _ticketUtilities.generateSelectKitContent)(list);
        props[`${type}None`] = {
          name: I18n.t('tickets.topic.select', {
            type
          })
        };
        this.setProperties(props);
      });
      if (topic.get('archetype') == 'private_message') {
        let includeGroup = this.siteSettings.tickets_include_group;
        const currentGroups = topic.get('content.details.allowed_groups');
        if (currentGroups && currentGroups.length) {
          let names = currentGroups.map(cg => cg.name);
          if (names.indexOf(includeGroup) > -1) includeGroup = null;
        }
        if (includeGroup) {
          this.setProperties({
            includeUsernames: [includeGroup],
            hasGroups: true
          });
          this.includedChanged();
        }
      }
    },
    toggleClasses(isTicket) {
      let classes = 'toggle-ticket';
      if (isTicket) classes += ' btn-primary';
      return classes;
    },
    showInclude() {
      return this.get('topic.archetype') === 'private_message';
    },
    updateOnChange(sender, key) {
      this.updateTicket(key);
    },
    updateOnToggle() {
      const isTicket = this.get('topic.is_ticket');
      if (isTicket) {
        this.addTickets();
      } else {
        this.removeTickets();
      }
    },
    updateTicket(ticketType) {
      const topic = this.get('topic');
      let ticket = this.get(ticketType);
      let list = this.get(`${ticketType}List`).map(function (value) {
        return value.name;
      });
      let tags = topic.get('tags') || [];
      if (list) {
        tags = tags.filter(t => list.indexOf(t) === -1);
      }
      if (ticket) {
        tags.push(ticket);
      }
      topic.set('tags', tags);
    },
    addTickets() {
      ticketTypes.forEach(type => this.updateTicket(type));
    },
    removeTickets() {
      const topic = this.get('topic');
      let tags = topic.get('tags');
      if (tags) {
        tags = tags.filter(t => !(0, _ticketUtilities.isTicketTag)(t));
        topic.set('tags', tags);
      }
    },
    includedChanged() {
      const hasGroups = this.get('hasGroups');
      const usernames = this.get('includeUsernames');
      let type = hasGroups ? 'groups' : 'users';
      this.set(`topic.allowed_${type}`, usernames.join(","));
    },
    actions: {
      toggleIsTicket() {
        this.toggleProperty('topic.is_ticket');
      },
      unassign() {
        this.taskActions.unassign(this.get('topic.id'), 'Topic');
      },
      assign() {
        this.taskActions.assign(this.get('topic'));
      },
      updateIncludeUsernames(selected, content) {
        if (!content.length) {
          this.setProperties({
            includeUsernames: [],
            hasGroups: false
          });
        } else {
          this.setProperties({
            includeUsernames: selected,
            hasGroups: content[0].isGroup || false
          });
        }
        this.includedChanged();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleClasses", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleClasses"), _obj), _applyDecoratedDescriptor(_obj, "showInclude", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "showInclude"), _obj), _applyDecoratedDescriptor(_obj, "updateOnChange", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateOnChange"), _obj), _applyDecoratedDescriptor(_obj, "updateOnToggle", [_dec3], Object.getOwnPropertyDescriptor(_obj, "updateOnToggle"), _obj)), _obj)));
});